import React, { useEffect, useState } from 'react';
import Messages, { MessagesTypes } from '../../utility/Messages';
import ForgotForm from './ForgotForm';
import track from '../../../lib/track';

export interface Props {
  messages: MessagesTypes;
}

export interface State {
  tracked: boolean;
}

export const ForgotDialogContents: React.FC<Props> = props => {
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (!tracked) {
      track.modalView('Forgot Dialog');
      setTracked(true);
    }
  }, []);

  return (
    <div className="save-dialog-inner" style={{ height: '100%' }}>
      <Messages messages={props.messages} />
      <ForgotForm />
    </div>
  );
};

export default ForgotDialogContents;
