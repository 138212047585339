import * as React from 'react';
import { connect } from 'react-redux';

import { isMobile } from '../../../lib/getDeviceSize';
import { MessagesTypes } from '../../utility/Messages';
import ResponsiveDialog from '../../utility/ResponsiveDialog';
import ForgotDialogContents from './ForgotDialogContents';
import * as types from '../../../actions/actionTypes';

import styles from '../../../styles/sass/components/ForgotDialog.module.scss';

export interface Props {
  dispatch: any;
  messages: MessagesTypes;
  open: boolean;
}

/**
 * Modal popup for starting a password reset
 * @param {type} props- lorem ipsum.
 */
export const ForgotDialog: React.FC<Props> = props => {
  const handleClose = () => {
    props.dispatch({ type: types.CloseForgotDialog });
  };

  return (
    <ResponsiveDialog
      mediumLargerPaper={isMobile() ? false : true}
      onClose={() => handleClose()}
      open={props.open}
      className="save-dialog"
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName="save-dialog-closeButton"
    >
      <ForgotDialogContents messages={props.messages} />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
  open: state.dialogs.forgotDialogOpen,
});

export default connect(mapStateToProps)(ForgotDialog);
