import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Button, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { ToriiPlum } from '../../utility/Icons';
import { forgotPassword } from '../../../actions/auth';
import TextField from '../../utility/TextField';
import validations, { composeValidators } from '../../../lib/validation';
import { buttonStyles } from '../../../styles/base';
import { normalizeText } from '../../../lib/helpers';

import styles from '../../../styles/sass/components/ForgotDialog.module.scss';

export interface Props {
  classes: any;
  dispatch: any;
}

/**
 * Create a form for users to indicate they forgot their password.
 * @class ForgotForm
 * @extends {React.Component}
 */
export class ForgotForm extends React.Component<Props> {
  handleSubmit = (values: any) => {
    this.props.dispatch(forgotPassword(values));
  };

  render() {
    const { classes } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, valid }) => (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ToriiPlum />
            <span className="heading">Forgot your password?</span>
            <span>
              Enter your email address and we&#39;ll send you reset
              instructions.
            </span>
            <div
              className="sign-up-dialog-form-container"
              style={{ width: '100%' }}
            >
              <div className="input-container">
                <InputLabel id="email-label" className="input-label">
                  Email Address
                </InputLabel>
                <Field
                  component={TextField}
                  format={normalizeText}
                  fullWidth
                  name="email"
                  type="email"
                  placeholder="email@email.com"
                  validate={composeValidators(
                    validations.required,
                    validations.email,
                  )}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  className="input"
                />
              </div>
            </div>
            <Button
              disabled={!valid}
              variant="contained"
              size="medium"
              className={classes.buttonMediumBlue}
              style={{
                width: '100%',
                height: 56,
                textTransform: 'capitalize',
                borderRadius: 10,
              }}
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        )}
      />
    );
  }
}

export default connect()(withStyles(buttonStyles)(ForgotForm));
